export const requiredRule = {
  required: true,
  type: 'required' as const,
  trigger: ['blur', 'change']
}

export const getRequiredFormRule = (text = 'This field') => [
  {
    ...requiredRule,
    message: `${text} is required`
  }
]

export const getOptionalFormRule = (isRequired?: boolean) => ({
  ...requiredRule,
  required: isRequired ?? false
})

export const getTemperatureCheckRule = (isRequired?: boolean) => ({
  required: isRequired ?? false,
  validator: (_: unknown, value: string) => {
    const regexp = /^[3,4]\d\.\d$/
    if (!regexp.test(value)) {
      return Promise.reject(
        'This field does not match pattern and should be between 30.0 and 49.9'
      )
    }
    return Promise.resolve()
  },
  trigger: ['blur', 'change']
})

export const booleanRadioOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]

<template>
  <FormItemWrapper
    type="select"
    :has-errors="hasErrors"
    :force-show-form="forceShowForm"
    :editable="editable"
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
  >
    <template #text>
      <a-form-item
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :rules="getOptionalFormRule(required)"
      >
        <div class="form-item-text">
          <a-spin :spinning="loading">
            {{ selectLabels || emptyText }}
          </a-spin>
        </div>
      </a-form-item>
    </template>
    <template #input="{ toggleEditMode }">
      <a-form-item
        ref="formModelItem"
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :rules="[getOptionalFormRule(required), ...customRules]"
        :name="prop"
      >
        <VSelect
          v-model:model-value="inputValue"
          :class="{ 'search-disabled': $attrs.searchable === false }"
          :auto-focus="autoFocus"
          option-filter-prop="children"
          :reduce="item => item.value"
          :loading="loading"
          v-bind="$attrs"
          :multiple="mode === 'multiple'"
          @close="
            $event => {
              toggleEditMode($event)
              onClose()
            }
          "
        />
      </a-form-item>
    </template>
  </FormItemWrapper>
</template>
<script>
import { isArray, isEqual } from 'lodash'
import { defineComponent } from 'vue'

import FormItemWrapper from '@/components/common/forms/FormItemWrapper.vue'
import HInputMixin from '@/mixins/HInputMixin.vue'
import { getOptionalFormRule } from '@/utils/form'

import VSelect from './VSelect.vue'

export default defineComponent({
  components: { FormItemWrapper, VSelect },
  mixins: [HInputMixin],

  props: {
    value: {
      type: [String, Number, Array]
    },
    loading: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'default'
    }
  },

  emits: ['input', 'update:value', 'change'],

  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.$emit('update:value', value)
        this.$emit('change', value)
        if (this.prop) this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    },
    selectLabels() {
      const getLabel = value =>
        this.getOptions().find(selectOption =>
          isEqual(selectOption.value, value)
        )?.label

      return isArray(this.inputValue)
        ? this.inputValue.map(value => getLabel(value) || value).join(', ')
        : getLabel(this.inputValue) || this.value
    }
  },

  methods: {
    getOptionalFormRule,
    getOptions() {
      return this.$attrs.options || []
    },
    onClose() {
      if (this.prop) this.$refs.formModelItem.onFieldBlur()
    }
  }
})
</script>
<style lang="scss" scoped>
:deep(.v-select) {
  &.search-disabled {
    .vs__search {
      position: absolute;
      // when search is disabled and we hide vs__search then
      // blur is not working (closing select when clicking outside it)
      width: 0;
      height: 0;
    }
  }

  .vs__actions {
    padding: 4px 20px 0 3px;
  }

  &.vs--single.vs--loading .vs__selected,
  &.vs--single.vs--open .vs__selected {
    padding-top: 3px;
  }
}
</style>

<template>
  <a-row
    v-if="pagination && pagination.pageSize"
    class="page-size-select"
    type="flex"
    justify="end"
  >
    <span class="label">Items per screen</span>
    <a-select
      v-model:value="pageSize"
      :options="pageSizeOptions"
      placeholder="Page size"
      style="width: 94px"
      :get-popup-container="() => $el.parentElement"
    />
  </a-row>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pagination: {
      type: [Boolean, Object],
      required: false
    },
    defaultPageSize: {
      type: Number,
      default: 50
    },
    useQueryParams: {
      type: Boolean,
      default: true
    }
  },

  emits: ['update:pagination'],

  data() {
    return {
      pageSizeOptions: [
        {
          label: 20,
          value: 20
        },
        {
          label: 30,
          value: 30
        },
        {
          label: 40,
          value: 40
        },
        {
          label: 50,
          value: 50
        }
      ]
    }
  },

  computed: {
    pageSize: {
      set(value) {
        if (this.useQueryParams) {
          this.setQueryParam('page_size', value)
        } else {
          this.$emit('update:pagination', {
            ...this.pagination,
            pageSize: value
          })
        }
        this.pagination.onChange(1, value)
      },
      get() {
        if (this.useQueryParams) {
          return this.getQueryParam('page_size') || this.defaultPageSize
        }
        return this.pagination.pageSize
      }
    }
  },

  watch: {
    pageSize: {
      handler(value, prevValue) {
        const currentPage = this.useQueryParams
          ? this.getQueryParam('page')
          : this.pagination.current
        if (value > prevValue && currentPage) {
          if (this.useQueryParams) {
            this.setQueryParam('page', 1)
          } else {
            this.$emit('update:pagination', {
              ...this.pagination,
              current: 1
            })
          }
        }
      }
    }
  }
})
</script>
<style lang="scss" scoped>
.page-size-select {
  flex: 1;
  align-items: center;
  width: 100%;
  margin: 0 0 16px;

  .label {
    margin: 0 8px;
    font-size: 12px;
    line-height: 20px;
  }

  :deep(.ant-select-selector) {
    height: 32px;

    .ant-select-selection-item {
      padding: 5px 0;
      line-height: 22px;
    }
  }
}
</style>

<template>
  <div>
    <PageSizeSelect
      :pagination="pagination"
      :default-page-size="defaultPageSize"
    />
    <AntList
      v-bind="{
        ...$props,
        ...$attrs
      }"
      :pagination="false"
    >
      <template v-for="(_, slot) in slots" #[slot]="scope">
        <slot :name="slot" v-bind="scope || {}" />
      </template>
    </AntList>

    <a-row
      v-if="pagination && pagination.pageSize"
      class="pagination"
      type="flex"
      align="middle"
      justify="end"
    >
      <BackToTopBtn v-if="!hideBackToTop" class="back-to-top" />
      <CustomPagination :pagination="pagination" />
    </a-row>
  </div>
</template>

<script setup lang="ts">
import { List as AntList } from 'ant-design-vue'

import type { Pagination } from '@/types/pagination'

import BackToTopBtn from './BackToTopBtn.vue'
import CustomPagination from './CustomPagination.vue'
import PageSizeSelect from './PageSizeSelect.vue'

withDefaults(
  defineProps<{
    pagination?: false | Pagination
    defaultPageSize?: number
    hideBackToTop?: boolean
  }>(),
  {
    pagination: false,
    defaultPageSize: undefined,
    hideBackToTop: false
  }
)

const slots = defineSlots()
</script>

<style lang="scss" scoped>
.pagination {
  padding: 16px 0;
}

:deep(.arrow-icon) {
  transition: 0.3s transform;

  .arrow-icon--rotated {
    transform: rotate(-180deg);
  }
}

.back-to-top {
  margin-right: 16px;
}
</style>

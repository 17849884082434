<template>
  <Component :is="IconComponent" :class="['anticon', `anticon-${type}`]" />
</template>

<script setup lang="ts">
/**
 * Purpose of this component is to allow smooth migration from removed <a-icon> component to the native icons from @ant-design/icons-vue
 * This component should not be used in new code
 */

import * as Icons from '@ant-design/icons-vue'
import { computed, watchEffect } from 'vue'

const props = withDefaults(
  defineProps<{
    type: IconString
    theme?: 'filled' | 'outlined' | 'twoTone'
  }>(),
  {
    theme: 'outlined'
  }
)

const SUPPORTED_ICONS = [
  'minus',
  'plus',
  'scan',
  'more',
  'left',
  'close',
  'right',
  'delete',
  'vertical-align-bottom',
  'tag',
  'calendar',
  'home',
  'monitor',
  'edit',
  'check',
  'arrow-left',
  'arrow-right',
  'download',
  'clock-circle',
  'close-circle',
  'plus-circle',
  'user',
  'form',
  'upload',
  'download',
  'cloud-download',
  'left',
  'right',
  'star',
  'carry-out',
  'link',
  'play-circle',
  'history',
  'swap',
  'reload',
  'up',
  'rise',
  'pic-center',
  'history',
  'info-circle',
  'retweet',
  'desktop',
  'mobile',
  'copy',
  'unordered-list',
  'save',
  'undo',
  'heart',
  'lock',
  'calendar',
  'profile',
  'file-done',
  'hourglass',
  'usergroup-add',
  'caret-up',
  'caret-down',
  'caret-right',
  'line',
  'fall',
  'search',
  'message',
  'paper-clip',
  'double-left',
  'double-right',
  'arrow-up',
  'down',
  'sliders',
  'team',
  'container',
  'coffee',
  'project',
  'fund',
  'setting',
  'bug',
  'phone',
  'exclamation-circle',
  'check-circle',
  'question-circle',
  'bulb',
  'loading',
  'filter',
  'interaction',
  'file-text',
  'unlock',
  'block',
  'laptop',
  'bank',
  'file',
  'medicine-box',
  'safety',
  'schedule',
  'gift',
  'book',
  'line-chart',
  'radar-chart'
] as const

/**
 * @deprecated use icons from @ant-design/icons-vue instead
 */
export type IconString = (typeof SUPPORTED_ICONS)[number]

const iconImportName = computed(() =>
  `${props.type}-${props.theme}`
    .split('-')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
    .join('')
)

// @ts-expect-error -- Type of Icons is not known by TS
const IconComponent = computed(() => Icons[iconImportName.value])

watchEffect(() => {
  if (!SUPPORTED_ICONS.includes(props.type)) {
    // eslint-disable-next-line no-console
    console.error(`[DeprecatedIcon] Icon ${props.type} is not supported`)
  }
})
</script>

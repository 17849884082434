import type { MaybeRef } from '@vueuse/core'

import { httpService } from '@/api/http.service'
import { useFetch } from '@/composables/useFetch'

export const useWsAuthTokenRetrieve = ({
  enabled = false
}: {
  enabled: MaybeRef<boolean>
}) =>
  useFetch({
    queryKey: ['wsAuthTokenRetrieve'],
    queryFn: () => httpService.ws.wsAuthTokenRetrieve(),
    enabled,
    refetchOnWindowFocus: false
  })

import * as Sentry from '@sentry/vue'
import { keyBy, reduce } from 'lodash'
import { set } from 'vue-gtag'
import { createStore as createVuexStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import { Teacher, User } from '@/api/admin'
import {
  Aim,
  Assignment,
  Auth,
  DraftActivity,
  GradeLevel,
  Quarter,
  Room,
  SchoolYear,
  Semester,
  Team
} from '@/api/index.js'
import router from '@/router'
import { destroyNotification } from '@/utils/notifications'

import courseMeta from './courseMeta'
import idpRules from './idpRules'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({ userInfo: state.userInfo })
})

const DEFAULT_USER_INFO = {
  permissions: [],
  profiles: [],
  is_superuser: false,
  has_perms_to_own_objects_only: true,
  can_generate_transcripts_of_grades: false,
  id: undefined,
  avatar: undefined,
  full_name: undefined,
  is_staff: undefined,
  can_report_bug: false
}

export const createStore = app =>
  createVuexStore({
    state: {
      userInfo: { ...DEFAULT_USER_INFO },
      idpRules: {
        numberOfLessonsChoices: []
      },
      aims: [],
      assignmentTypes: [],
      defaultAim: {},
      genders: [],
      grades: [],
      gradesOptions: [],
      semesters: [],
      semestersMap: {},
      currentSemester: {},
      quarters: [],
      quartersMap: {},
      quarterOptions: [],
      currentQuarter: {
        id: undefined,
        short_name: ''
      },
      years: [],
      yearsOptions: [],
      currentYear: {},
      staffTypes: [],
      teachers: [],
      rooms: [],
      hours: [],
      days: [],
      staffUsersOptions: [],
      teams: [],
      teamOptions: [],
      myTeams: [],
      myTeamsOptions: [],
      paginationTotal: null,
      safeModeDisabled: false, // true -> admin mode / false -> safe mode,
      isSidebarOpen: false,
      externalLink: null
    },
    mutations: {
      setUserInfo(state, payload) {
        state.userInfo = payload
      },
      setAims(state, payload) {
        state.aims = payload
        state.defaultAim = state.aims.find(aim => aim.is_default)
      },
      setGrades(state, payload) {
        state.grades = payload
        state.gradesOptions = payload.map(grade => ({
          value: grade.id,
          label: grade.name
        }))
      },
      setAssignmentTypes(state, payload) {
        state.assignmentTypes = payload
      },
      setSemesters(state, payload) {
        state.semesters = payload
        state.semestersMap = keyBy(payload, 'id')
      },
      setCurrentSemester(state, payload) {
        state.currentSemester = payload
      },
      setYears(state, payload) {
        state.years = payload
        state.yearsOptions = payload.map(year => ({
          value: year.id,
          label: year.name
        }))
      },
      setCurrentYear(state, payload) {
        state.currentYear = payload
      },
      setQuarters(state, payload) {
        state.quarters = payload
        state.quarterOptions = payload.map(quarter => ({
          value: quarter.id,
          label: quarter.name
        }))
        state.quartersMap = keyBy(payload, 'id')
      },
      setCurrentQuarter(state, payload) {
        state.currentQuarter = payload
      },
      setStaffTypes(state, payload) {
        state.staffTypes = payload
      },
      setGenders(state, payload) {
        state.genders = payload
      },
      setDays(state, payload) {
        state.days = payload
      },
      setHours(state, payload) {
        state.hours = payload
      },
      setTeachers(state, payload) {
        state.teachers = payload.map(teacher => ({
          value: teacher.id,
          label: teacher.full_name
        }))
      },
      setRooms(state, payload) {
        state.rooms = payload.map(room => ({
          value: room.id,
          label: room.name
        }))
      },
      setStaffUsersOptions(state, payload) {
        state.staffUsersOptions = payload.map(user => ({
          value: user.id,
          label: `${user.first_name} ${user.last_name}`
        }))
      },
      setTeams(state, payload) {
        state.teams = payload
        state.teamOptions = payload.map(obj => ({
          label: obj.name,
          value: obj.id
        }))

        state.myTeams = payload.filter(obj =>
          obj.members.find(member => member.user === state.userInfo.id)
        )
        state.myTeamsOptions = state.myTeams.map(obj => ({
          label: obj.name,
          value: obj.id
        }))
      },
      clearUserInfo(state) {
        destroyNotification()
        state.userInfo = { ...DEFAULT_USER_INFO }
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('websocketToken')
        window.dispatchEvent(new Event('ws-disconnect'))
        window.localStorage.removeItem('boardingRotaRequestId')
        router.push({ name: 'Login' })
      },
      setSafeModeDisabled(state, safeModeDisabled) {
        state.safeModeDisabled = safeModeDisabled
        window.localStorage.setItem('safeModeDisabled', safeModeDisabled)
      },

      toggleIsSidebarOpen(state) {
        state.isSidebarOpen = !state.isSidebarOpen
      },
      closeSidebar(state) {
        state.isSidebarOpen = false
      },
      setExternalLink(state, value) {
        state.externalLink = value
      },
      setPaginationTotal(state, value) {
        state.paginationTotal = value
      }
    },
    actions: {
      fetchUserInfo({ commit }) {
        return Auth.me()
          .then(({ data: user }) => {
            Sentry.setUser({
              id: user.id,
              username: user.username,
              email: user.email
            })
            set({ user_id: user.id })

            if (app.config.globalProperties.$hj) {
              app.config.globalProperties.$hj('identify', user.id, {
                email: user.email
              })
            }
            commit('setUserInfo', user)
          })
          .catch(e => {
            // TODO handle errors not just catch and ignore
            // eslint-disable-next-line no-console
            console.error('[fetchUserInfo Error]', e)
          })
      },
      fetchAims({ commit }) {
        return Aim.all().then(res => commit('setAims', res.data.results))
      },
      fetchGrades({ commit }) {
        return GradeLevel.all().then(res =>
          commit('setGrades', res.data.results)
        )
      },
      fetchSemesters({ commit }) {
        return Semester.all().then(res => {
          commit('setSemesters', res.data.results)
          commit('setCurrentSemester', res.data.current_period)
        })
      },
      fetchQuarters({ commit }) {
        return Quarter.all().then(res => {
          commit('setQuarters', res.data.results)
          commit('setCurrentQuarter', res.data.current_period)
        })
      },
      fetchYears({ commit }) {
        return SchoolYear.all().then(res => {
          commit('setYears', res.data.results)
          commit('setCurrentYear', res.data.current_period)
        })
      },
      fetchAssignmentTypes({ commit }) {
        return Assignment.fetchAssignmentTypes().then(res => {
          commit(
            'setAssignmentTypes',
            res.data.map(item => ({
              value: item.id,
              label: item.name
            }))
          )
        })
      },
      fetchStaffTypes({ commit }) {
        return User.staffTypes().then(res => {
          commit('setStaffTypes', res.data)
        })
      },
      fetchGenders({ commit }) {
        return User.genders().then(res => {
          commit('setGenders', res.data)
        })
      },
      fetchStaffUsersOptions({ commit }) {
        return Team.fetchStaffList().then(res => {
          commit('setStaffUsersOptions', res.data)
        })
      },
      fetchTeams({ commit }) {
        return Team.all().then(res => {
          commit('setTeams', res.data.results)
        })
      },
      fetchTeachers({ commit }, props) {
        const params = { query: '{id,full_name}', ...props }
        return Teacher.all(params).then(res => {
          commit('setTeachers', res.data.results)
        })
      },
      fetchRooms({ commit }) {
        return Room.all().then(res => {
          commit('setRooms', res.data.results)
        })
      },
      fetchDays({ commit }) {
        return DraftActivity.days().then(res => {
          commit('setDays', res.data)
        })
      },
      fetchHours({ commit }) {
        return DraftActivity.hours().then(res => {
          commit('setHours', res.data)
        })
      },
      logout({ commit }) {
        return Auth.logout().then(() => commit('clearUserInfo'))
      },
      fetchCommonData() {
        this.dispatch('fetchAims')
        this.dispatch('fetchGrades')
        this.dispatch('fetchAssignmentTypes')
        this.dispatch('fetchQuarters')
        this.dispatch('fetchSemesters')
        this.dispatch('fetchYears')
        this.dispatch('fetchStaffTypes')
        this.dispatch('fetchGenders')
      }
    },
    modules: {
      courseMeta,
      idpRules
    },
    plugins: [vuexLocal.plugin],
    getters: {
      getIsSidebarOpen: state => state.isSidebarOpen,
      getCurrentQuarter: state => state.currentQuarter,
      gradeLevelIdToName: state =>
        reduce(
          state.gradesOptions,
          function (obj, param) {
            obj[param.value] = param.label
            return obj
          },
          {}
        ),
      getPeriodByName: state => (periodName, yearId) => {
        // return object with name (quarter or semester) and value id
        const quarter = state.quarters.find(
          selectedQuarter =>
            selectedQuarter.short_name === periodName &&
            selectedQuarter.school_year === yearId
        )
        const semester = state.semesters.find(
          selectedSemester =>
            selectedSemester.short_name === periodName &&
            selectedSemester.school_year === yearId
        )
        return {
          quarterId: quarter?.id,
          semesterId: semester?.id
        }
      },
      isAdminMode: state => state.safeModeDisabled,
      canSwitchMode: state =>
        !!(
          (state.userInfo.is_staff && state.userInfo.profiles.length > 1) ||
          (state.userInfo.is_superuser && state.userInfo.profiles.length >= 1)
        ),
      // Permissions.
      hasPerm: state => perm => state.userInfo.permissions.includes(perm),
      isTeamLeader: state => team =>
        team.members.some(
          item => item.role === 2 && item.user === state.userInfo.id
        ),
      canViewTeachersTimetable: state =>
        state.userInfo.permissions.includes(
          'permissions.view_teachers_timetable'
        ),
      canChangeCover: state =>
        state.userInfo.permissions.includes('permissions.change_cover'),
      canEditCustomGrade: state =>
        state.userInfo.permissions.includes('permissions.change_custom_grade'),
      canAddMerit: state =>
        state.userInfo.permissions.includes('merits.add_merit'),
      canDownloadMeritReport: state =>
        state.userInfo.permissions.includes(
          'permissions.download_merit_report'
        ),
      canViewAccGrade: state =>
        state.userInfo.permissions.includes('grades.view_accgrade'),
      canAddAbsence: state =>
        state.userInfo.permissions.includes('attendances.add_absence'),
      canEditAbsence: state =>
        state.userInfo.permissions.includes('attendances.change_absence'),
      canRemoveAbsence: state =>
        state.userInfo.permissions.includes('attendances.delete_absence'),
      canEditAccGradeComment: state =>
        state.userInfo.permissions.includes('grades.change_accgrade'),
      canViewAccGradeComment: state =>
        state.userInfo.permissions.includes('grades.view_accgrade'),
      canAddWeeklyComment: state =>
        state.userInfo.permissions.includes('courses.add_weeklycomment'),
      canEditWeeklyComment: state =>
        state.userInfo.permissions.includes('courses.change_weeklycomment'),
      canRemoveWeeklyComment: state =>
        state.userInfo.permissions.includes('courses.delete_weeklycomment'),
      canEditStudentAimForCourse: state =>
        state.userInfo.permissions.includes('aims.change_studentaimforcourse'),
      canViewStudentAimForCourse: state =>
        state.userInfo.permissions.includes('aims.view_studentaimforcourse'),
      canAddCourse: state =>
        state.userInfo.permissions.includes('courses.add_course'),
      canAddSection: state =>
        state.userInfo.permissions.includes('sections.add_section'),
      canRemoveSection: state =>
        state.userInfo.permissions.includes('sections.delete_section'),
      canRunGenerator: state =>
        state.userInfo.permissions.includes(
          'remote_generator.add_generationtask'
        ),
      canAddStudent: state =>
        state.userInfo.permissions.includes('students.add_student'),
      canEditStudent: state =>
        state.userInfo.permissions.includes('students.change_student'),
      canViewStudent: state =>
        state.userInfo.permissions.includes('students.view_student'),
      canAddAssignmentGrade: state =>
        state.userInfo.permissions.includes('permissions.assignment_grade'),
      canViewAssignment: state =>
        state.userInfo.permissions.includes('assignments.view_assignment'),
      canAddStudentIdpPlan: state =>
        state.userInfo.permissions.includes('students_idp.add_studentidpplan'),
      canViewTeachersManagement: state =>
        state.userInfo.permissions.includes(
          'permissions.view_teachers_management'
        ),
      canViewUsersManagement: state =>
        state.userInfo.permissions.includes(
          'permissions.view_users_management'
        ),
      canDownloadActivitiesPerSlotReport: state =>
        state.userInfo.permissions.includes(
          'permissions.download_activities_per_slot_report'
        ),
      canEditExam: state =>
        state.userInfo.permissions.includes('exams.change_studentexternalexam'),
      canEditCreditsHistory: state =>
        state.userInfo.permissions.includes(
          'requirements.change_importedstudentcreditshistory'
        )
    }
  })

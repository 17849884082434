<template>
  <FormItemWrapper
    type="input"
    :has-errors="hasErrors"
    :force-show-form="forceShowForm"
    :editable="editable"
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
    :query-selector-function="item => item.click()"
  >
    <template #text>
      <a-form-item
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :rules="getOptionalFormRule(required)"
      >
        <div class="form-item-text">{{ formattedDate || emptyText }}</div>
      </a-form-item>
    </template>
    <template #input="{ setEditMode }">
      <a-form-item
        ref="formModelItem"
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :rules="[getOptionalFormRule(required), ...customRules]"
        :name="prop"
      >
        <a-date-picker
          v-model:value="inputValue"
          :autofocus="autoFocus"
          v-bind="$attrs"
          @open-change="isOpen => setEditMode(isOpen)"
        />
      </a-form-item>
    </template>
  </FormItemWrapper>
</template>
<script>
import moment from 'moment'
import { defineComponent } from 'vue'

import FormItemWrapper from '@/components/common/forms/FormItemWrapper.vue'
import { DATE_TIME_FORMAT } from '@/constants/time-format'
import HInputMixin from '@/mixins/HInputMixin.vue'
import { getOptionalFormRule } from '@/utils/form'

export default defineComponent({
  components: { FormItemWrapper },
  extends: HInputMixin,

  props: {
    value: {
      type: [String, Object]
    }
  },

  emits: ['input', 'update:value'],

  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.$emit('update:value', value)
        if (this.prop) this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    },
    formattedDate() {
      const format = this.$attrs.format || DATE_TIME_FORMAT
      if (this.inputValue) {
        return moment(this.inputValue).format(format)
      }
      return ''
    }
  },
  methods: {
    getOptionalFormRule
  }
})
</script>
<style lang="scss" scoped>
.form-item-wrapper {
  .ant-calendar-picker {
    width: 100%;
  }
}
</style>

<template>
  <div
    ref="formWrapper"
    class="form-item-wrapper"
    :class="[
      {
        'form-item-wrapper--editable': editable,
        'form-item-wrapper--form-visible': isFormVisible,
        'form-item-wrapper--has-errors': hasErrors,
        'form-item-wrapper--no-wrap-text': noWrapText
      },
      `form-item-wrapper--${focusBackgroundColor}-hover-background-color`,
      `form-item-wrapper--${type}-wrapper-type`
    ]"
  >
    <div v-if="isFormVisible" class="form-item-input-wrapper">
      <slot
        name="input"
        :toggle-edit-mode="toggleEditMode"
        :set-edit-mode="setEditMode"
        :has-errors="hasErrors"
      />
    </div>
    <div v-else class="form-item-text-wrapper" @click="toggleEditMode">
      <slot name="text" />
    </div>
  </div>
</template>
<script>
import { nextTick, defineComponent } from 'vue'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'input'
    },
    hasErrors: {
      type: Boolean,
      default: false
    },
    forceShowForm: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    noWrapText: {
      type: Boolean,
      default: false
    },
    querySelector: {
      type: String,
      default: 'input'
    },
    querySelectorFunction: {
      type: Function,
      default: item => item?.focus()
    },
    focusBackgroundColor: {
      type: String,
      default: 'white'
    }
  },

  data() {
    return {
      editMode: false
    }
  },

  computed: {
    isFormVisible() {
      return (
        this.editable && (this.forceShowForm || this.editMode || this.hasErrors)
      )
    }
  },

  created() {
    if (this.forceShowForm) {
      this.editMode = true
    }
  },

  methods: {
    toggleEditMode($event) {
      if (this.editable) {
        this.editMode = !this.editMode

        if (this.editMode) {
          const target = this.$refs.formWrapper || $event?.target
          if (target) {
            nextTick(() => {
              const targetEl = target.querySelector(this.querySelector)
              if (targetEl) this.querySelectorFunction(targetEl)
            })
          }
        }
      }
    },
    setEditMode(value) {
      this.editMode = !!value
    }
  }
})
</script>
<style lang="scss" scoped>
.form-item-wrapper {
  --wrapper-padding: 12px 0;
  --inner-wrapper-padding-top-bottom: 9px;
  --inner-wrapper-padding-left-right: 19px;
  --hover-background-color: #ffffff;
  --transition-time: 0.2s;
  --gray-hover-background-color: var(--neutral-grey-color);
  --form-item-label-color: var(--gray-color);
  --form-item-label-font-size: 12px;
  --placeholder-color: var(--light-gray-color);

  display: flex;
  align-items: center;
  padding: var(--wrapper-padding);

  &--gray-hover-background-color {
    --hover-background-color: var(--gray-hover-background-color);
  }

  &--select-wrapper-type {
    --inner-wrapper-padding-top-bottom: 9px;
    --inner-wrapper-padding-left-right: 17px;
  }

  &--input-wrapper-type {
    --inner-wrapper-padding-top-bottom: 9px;
    --inner-wrapper-padding-left-right: 19px;
  }

  &--no-wrap-text {
    div.form-item-text-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--editable:not(&--form-visible) {
    cursor: pointer;
    border-radius: 12px;

    &:hover {
      :deep(.ant-form-item),
      :deep(.ant-form-item-label),
      :deep(.ant-form-item-required) {
        cursor: pointer;
      }

      :deep(.form-item-text),
      .form-item-text {
        background: var(--hover-background-color);
        border-radius: 12px;
        transition: background-color var(--transition-time);
      }
    }
  }

  :deep(.ant-form-item-label) {
    font-size: 12px;
    line-height: 20px;

    & > label {
      margin-left: 16px;
      font-size: var(--form-item-label-font-size);
      color: var(--form-item-label-color);

      &.ant-form-item-required {
        margin-left: 8px;
      }
    }
  }

  .form-item-text-wrapper {
    .form-item-text,
    :deep(.form-item-text) {
      padding: var(--inner-wrapper-padding-top-bottom)
        var(--inner-wrapper-padding-left-right);
    }
  }

  .form-item-text-wrapper,
  .form-item-input-wrapper {
    width: 100%;

    :deep(.ant-form-item) {
      margin-bottom: 0;

      .ant-form-explain {
        padding-top: 4px;
        padding-left: var(--inner-wrapper-padding-left-right);
      }

      .ant-form-item-label > label::after {
        top: unset;
      }

      .ant-form-item-control {
        line-height: 40px;
      }

      .ant-form-item-children {
        line-height: 22px;
      }
    }
  }

  :deep(.ant-input::placeholder),
  :deep(input::placeholder) {
    color: var(--placeholder-color);
  }
}
</style>

import type Icon from '@ant-design/icons-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import type { ModalFuncProps } from 'ant-design-vue'
import { Modal } from 'ant-design-vue'
import type { ModalFunc } from 'ant-design-vue/lib/modal/Modal'
import { h } from 'vue'

type ModalType = 'confirm' | 'error' | 'info' | 'success' | 'warning'

export type ModalInstance = ReturnType<ModalFunc>

export function useModal() {
  const bodyElement = document.querySelector('body')
  const widthStyle = 'width: calc(100% - 15px)'

  const isScrollVisible = (element: HTMLElement) =>
    element.scrollHeight > element.clientHeight

  const addModalVisibilityListener = () => {
    const observer = new MutationObserver(() => {
      const body = document.querySelector('body')
      const header = document.querySelector('.header')

      if (!body || !header) return

      if (
        isScrollVisible(body) &&
        body.getAttribute('style')?.includes(widthStyle)
      ) {
        header.setAttribute('style', widthStyle)
      } else {
        header.setAttribute('style', '')
      }
    })

    if (!bodyElement) return

    observer.observe(bodyElement, {
      attributes: true
    })

    return observer
  }

  const removeModalVisibilityListener = (observer?: MutationObserver) =>
    observer && observer.disconnect()

  const showInformationModal = (
    type: ModalType,
    params: ModalFuncProps & { iconElement?: typeof Icon }
  ): ModalInstance => {
    const modalParams = {
      ...params,
      centered: true,
      icon: h(InfoCircleOutlined)
    }

    if (params.iconElement) modalParams.icon = h(params.iconElement)

    return Modal[type](modalParams)
  }

  return {
    addModalVisibilityListener,
    removeModalVisibilityListener,
    showInformationModal
  }
}

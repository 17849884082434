import type { Route } from '@/types/route'

import { getFirstPossibleRoute } from './utils/redirectHelpers'

const dashboardChildrenRoutes = [
  {
    path: '',
    name: 'dashboard-view' as const,
    component: () => import('@/views/Dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      permissions: ['tasks_management.view_task']
    },
    redirect: { name: 'dashboard-tasks' },
    children: [
      {
        path: 'tasks',
        name: 'dashboard-tasks' as const,
        component: () => import('@/views/Dashboard/Tasks/Tasks.vue'),
        meta: {
          title: 'Tasks'
        }
      }
    ]
  }
] satisfies Route[]

export const dashboardRoutes = [
  {
    path: 'dashboard',
    name: 'dashboard' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'My dashboard'
    },
    redirect: () => getFirstPossibleRoute(dashboardChildrenRoutes),
    children: dashboardChildrenRoutes
  }
] satisfies Route[]

import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { Modal, type ModalFuncProps } from 'ant-design-vue'
import { createVNode } from 'vue'

export const confirmationModal = (params: ModalFuncProps) =>
  Modal.confirm({
    icon: createVNode(InfoCircleOutlined),
    ...params
  })

export function deleteInstance(handlerOk: () => void) {
  confirmationModal({
    title: 'Delete',
    maskClosable: true,
    content: 'Are you sure you want to delete the item?',
    okText: 'Delete',
    cancelText: 'Cancel',
    onOk: handlerOk
  })
}

export function cancelInstance({
  handlerOk,
  itemName,
  content
}: {
  handlerOk: () => void
  itemName: string
  content?: string
}) {
  confirmationModal({
    title: `Are you sure you want to cancel ${itemName}?`,
    okText: 'Save changes',
    cancelText: 'Cancel',
    content,
    onOk: handlerOk
  })
}

export function unsavedChangesConfirm({
  handlerOk,
  handlerCancel,
  title = 'Unsaved changes',
  content = 'To not lose changes, continue editing\nand click save at the bottom of the page.',
  okText = 'Continue editing',
  cancelText = "Don't save changes",
  maskClosable = true,
  centered
}: {
  handlerOk?: () => void
  handlerCancel?: () => void
  title?: string
  content?: string
  okText?: string
  cancelText?: string
  maskClosable?: boolean
  centered?: boolean
}) {
  confirmationModal({
    title,
    maskClosable,
    content,
    okText,
    cancelText,
    centered,
    onOk: handlerOk,
    onCancel: handlerCancel
  })
}
